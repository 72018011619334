<template>
  <c-modal
    :is-open="isOpen"
    :close-on-overlay-click="false"
    is-centered
    size="2xl"
  >
    <c-modal-content
      ref="content"
      class="modalss"
    >
      <c-modal-body>
        <CImage
          src="https://ik.imagekit.io/dietela/pwa_webp/popup/popup_changepassword_success.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1677042871711"
          alt="success"
          pos="relative"
          z-index="1"
          mx="auto"
          mb="30px"
          h="170px"
        />
        <CHeading
          as="h1"
          color="primary.400"
          :font-size="['20px','28px']"
          font-weight="600"
          margin-bottom="30px"
          font-family="Roboto"
        >
          Password Berhasil Dibuat!
        </CHeading>
        <CText
          color="darkGray.900"
          :font-size="['12px','16px']"
          line-hieght="27px"
          font-family="Roboto"
        >
          Selamat! Password kamu berhasil dibuat. <br>
          Silahkan masuk ke web Dietela untuk memulai perjalanan dietmu.
        </CText>
        <router-link to="/auth">
          <BaseButton
            size="medium"
            :width="['inherit','100%']"
            border-radius="1000px"
            margin-top="30px"
          >
            Login Dietela
          </BaseButton>
        </router-link>
      </c-modal-body>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import iconHome from '@/assets/ic-home.svg'
import BaseButton from '@/components/elements/base-button.vue'

export default {
  components: {
    BaseButton,
  },
  props: {
    isOpen: {
      type: Boolean,
    },
  },
  data() {
    return {
      iconHome,
    }
  },
}
</script>
<style scoped>
.modalss::v-deep section {
  border-radius: 16px;
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
}

.link {
  display: block;
  color: #008C81;
  cursor: pointer;
}
.link-disabled {
  display: block;
  color: #a2a2a2;
}
.link:hover {
  text-decoration: underline
}

@media only screen and (min-width: 640px) {
  .link, .link-disabled {
    display: inline-block;
    margin-left: 16px;
  }
}
</style>