var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    class: {
      'button-solid': _vm.variant === 'solid',
      'button-outlined': _vm.variant === 'outlined',
      'button-withIcon': !!_vm.iconStart || !!_vm.iconEnd
    },
    attrs: {
      "disabled": _vm.disabled,
      "type": _vm.type
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.iconStart,
      expression: "iconStart"
    }],
    staticStyle: {
      "height": "18px"
    },
    attrs: {
      "src": _vm.iconStart,
      "alt": "icon start"
    }
  }), _vm._t("default", function () {
    return [_vm._v("Button")];
  }), _c('img', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.iconEnd,
      expression: "iconEnd"
    }],
    staticStyle: {
      "height": "18px"
    },
    attrs: {
      "src": _vm.iconEnd,
      "alt": "icon end"
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }