<template>
  <div class="flex flex-col justify-center items-center py-6 md:py-0 md:px-6 md:flex-row md:gap-16">
    <header class="flex flex-col items-center gap-4 w-full md:w-[400px]">
      <img
        class="header__icon-dietela"
        src="https://ik.imagekit.io/dietela/pwa_webp/new_landing_page/logo-color@3x.webp?updatedAt=1689904664797"
        alt="icon logo dietela"
      >
      <img
        class="header__icon-login"
        :src="require('@/assets/images/illustration-5-raw.jpeg')"
        alt="icon login dietela"
      >
    </header>
    <main class="flex flex-col items-center justify-center w-full px-5 md:px-0 md:py-16 md:h-screen md:overflow-auto md:w-[400px] md:scrollbar-hide">
      <div class="bg-dgreen w-full text-center text-white p-2.5 rounded-t-lg mt-4 text-sm font-medium md:mt-0">
        <h1>
          Buat Password
        </h1>
      </div>
      <div class="flex flex-col w-full">
        <FormulateForm
          v-slot="{ isValid, isLoading }"
          v-model="formValuesChangePassword"
          class="tab-form"
          :debounce="50"
          @submit="handleSubmitChangePassword"
        >
          <FormulateInput
            name="password"
            label="Password baru"
            :icon-prefix="iconPassword"
            :icon-prefix-style="{
              marginTop: '-2px',
            }"
            placeholder="Masukkan kata sandi"
            validation="required|min:8,length|hasAlphaNum|hasSpecialCharacter|hasUpperCase|hasLowerCase"
            type="password"
          />
          <FormulateInput
            name="password_confirm"
            label="Ulangi password"
            :icon-prefix="iconPassword"
            :icon-prefix-style="{
              marginTop: '-2px',
            }"
            placeholder="Masukkan kata sandi"
            validation="required|confirm"
            validation-name="Password"
            type="password"
          />
          <div class="tab-form-action">
            <d-button
              type="submit"
              :disabled="!isValid || isLoading"
            >
              Simpan
            </d-button>
          </div>
        </FormulateForm>
      </div>
    </main>
    <ModalSuccessSetPassword
      v-show="isModalSuccessChangePassword"
      :is-open="isModalSuccessChangePassword"
    />
  </div>
</template>

<script >
import { computed, ref, onMounted } from '@vue/composition-api';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import iconPassword from '@/assets/ic-padlock.svg';
import DButton from '@/components/elements/d-button.vue';
import { useActions } from '@/stores';
import { useToast } from '@chakra-ui/vue';
import ModalSuccessSetPassword from '@/components/widgets/modal-success-set-password.vue';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const toast = useToast();
  const router = useRouter();
  const route = useRoute();
  const {
    setPassword
  } = useActions('auth', ['setPassword']);
  const isModalSuccessChangePassword = ref(false);
  const formValuesChangePassword = ref({
    password: '',
    password_confirm: ''
  });
  const hasQueryToken = computed(() => !!route.query.token);
  const token = computed(() => route.query.token);

  const handleSubmitChangePassword = async _formValuesChangePassword => {
    try {
      await setPassword({
        token: token.value,
        password: _formValuesChangePassword.password
      });
      isModalSuccessChangePassword.value = true;
      toast({
        status: 'success',
        title: 'Success',
        description: 'Sukses membuat kata sandi',
        duration: 3000
      });
    } catch (e) {
      toast({
        status: 'error',
        title: 'Error',
        description: e?.data?.message || 'Error!',
        duration: 3000
      });
    }
  };

  onMounted(async () => {
    if (!hasQueryToken.value) {
      router.push({
        name: 'auth'
      });
    }
  });
  return {
    iconPassword,
    isModalSuccessChangePassword,
    formValuesChangePassword,
    handleSubmitChangePassword
  };
};

__sfc_main.components = Object.assign({
  DButton,
  ModalSuccessSetPassword
}, __sfc_main.components);
export default __sfc_main;
</script>

<style scoped>
.header__icon-dietela{
  width: 110px;
}
.header__icon-login{
  width: 60%;
}
.tab-form {
  padding: 25px;
  border: 2px solid #008C81;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.tab-form-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.tab-form-action__link > a {
  font-size: 12px;
  font-weight: 500;
  color: #008C81;
  cursor: pointer;
}

.formulate-input::v-deep .formulate-input-errors  {
  color: red;
  margin-left: 20px;
  font-size: 14px;
  margin-top: 6px;
}

@media (min-width: 640px) {
  .tab-form {
    height: 100%;
    overflow: auto;

  }

  .tab-form::-webkit-scrollbar {
    display: none;
  }

  .header__icon-login{
    width: 100%;
    max-width: 450px;
  }

  .header__icon-dietela {
    width: 80px;
  }
}

.checkbox::v-deep div {
  font-size: 12px;
}
</style>
