<template>
  <button
    :disabled="disabled"
    :class="{
      'button-solid': variant === 'solid',
      'button-outlined': variant === 'outlined',
      'button-withIcon': !!iconStart || !!iconEnd,
    }"
    :type="type"
    @click="$emit('click')"
  >
    <img
      v-show="iconStart"
      style="height: 18px;"
      :src="iconStart"
      alt="icon start"
    >
    <slot>Button</slot>
    <img
      v-show="iconEnd"
      style="height: 18px;"
      :src="iconEnd"
      alt="icon end"
    >
  </button>
</template>

<script>
export default {
  name: 'DButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'solid',
    },
    iconStart: {
      type: String,
      default: '',
    },
    iconEnd: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'button',
    },
  },
}
</script>

<style scoped>
.button-withIcon {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.button-outlined {
  font-weight: 500;
  font-size: 14px;
  padding: 14.5px;
  //padding: calc(14.5px - var(--border-size)) 14.5px;
  border-radius: 100px;
  width: 100%;
  color: #9a9999;
  //border: var(--border-size) solid #C4C4C4;
  box-shadow: inset 0 0 0 1.3px #C4C4C4;
}

.button-solid {
  font-weight: 500;
  font-size: 14px;
  padding: 14.5px;
  border-radius: 100px;
  width: 100%;
  color: white;
  background-color: #008c81;
}

.button-solid:disabled {
  background-color: #C4C4C4;
}
</style>